import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="documents"
export default class extends Controller {
  static targets = ["expandable", "expand", "constrict", "divider"];

  expand() {
    this.expandableTarget.classList.remove("hidden");
    this.expandTarget.classList.add("hidden");
    this.constrictTarget.classList.remove("hidden");
    this.dividerTarget.classList.add("hidden");
  }

  constrict() {
    this.expandableTarget.classList.add("hidden");
    this.expandTarget.classList.remove("hidden");
    this.constrictTarget.classList.add("hidden");
    this.dividerTarget.classList.remove("hidden");
  }
}
