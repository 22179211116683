import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "button"];
  static classes = ["active", "inactive"];

  connect() {
    this.updateState();
  }

  handleClick(event) {
    const currentValue = this.inputTarget.value == "true";
    this.inputTarget.value = !currentValue;

    this.updateState();
  }

  updateState() {
    if (this.inputTarget.value == "true") {
      this.element.classList.remove(...this.inactiveClasses);
      this.element.classList.add(...this.activeClasses);
      this.buttonTarget.classList.remove('translate-x-0')
      this.buttonTarget.classList.add('translate-x-5')
    } else {
      this.element.classList.remove(...this.activeClasses);
      this.element.classList.add(...this.inactiveClasses);
      this.buttonTarget.classList.remove('translate-x-5')
      this.buttonTarget.classList.add('translate-x-0')
    }
  }
}
