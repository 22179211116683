import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="balance-sheet"
export default class extends Controller {
  static targets = ["elementToToggle"];
  connect() {
  }
  exibirOcultarObservacao(event) {
    const actionValue = event.target.value;
    this.elementToToggleTarget.classList.remove("hidden");
    if (actionValue == "AP") {
      this.elementToToggleTarget.classList.add("hidden");
    }
  }
  createParecer(event) {
    const balancete_controle_id = $j("#balancete_controle_id").val();
    const acao = $j("#acao").val();
    const observacao = $j("#observacao").val();

    $j("#msg_obs").addClass("hidden");
    if (acao != "AP" && observacao.trim().length === 0) {
      $j("#msg_obs").removeClass("hidden");
      $j("#observacao").focus();
      return false;
    }

    const AUTH_TOKEN = $j('meta[name=csrf-token]').attr('content');
    $j.post("/balancete_analitico/update?&authenticity_token=" + AUTH_TOKEN,
      { balancete_controle_id: balancete_controle_id, observacao: observacao, acao: acao }, function (data) {
        console.log(data);
        if (data['ok'] == true) {
          location.reload();
        }
      })
  }
}
