import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="batch-row"
export default class extends Controller {
  static targets = ["submitButton"];

  connect() {}

  disableButton(event) {
    console.log("BORA");
    this.#setLoading(this.submitButtonTarget);
  }

  #setLoading(target) {
    this.#disable(target);
  }

  #disable(target) {
    target.disabled = true;
  }
}
