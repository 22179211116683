import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="payment-check"
export default class extends Controller {
  static targets = ["form"];

  connect() {}

  changeEmployee(event) {
    this.formTarget.submit();
  }
}
