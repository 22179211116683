import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="billet"
export default class extends Controller {
  static targets = ["check", "email", "boleto_ids", "msg_email"];

  connect() {}

  selectAll() {
    this.checkTargets.map((item) => (item.checked = true));
  }

  deselectAll() {
    this.checkTargets.map((item) => (item.checked = false));
  }
  sendEmail(event) {
    const email = this.emailTarget.value
    const boleto_ids = event.params['boletoIds'];

    this.msg_emailTarget.textContent = "Solicitando envio...";
    fetch(`/segunda_via_boleto/enviar_email?email=${email}&boleto_ids=${boleto_ids}`)
    .then(response => response.json())
    .then(res => {
      this.msg_emailTarget.textContent = res["msg"];
    })
  }

}
