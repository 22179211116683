import { Controller } from "@hotwired/stimulus"

function convertToNumber(str) {
  const num = Number(str.replace(/[^0-9,-]+/g, "").replace(",", "."));
  return num;
}

function convertToString(num) {
  const str = num.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
  return str;
}

// Connects to data-controller="lancamento-vts-sum"
export default class extends Controller {
  static targets = ["total", "total_sum"];

  connect() {
    this.updateSum();
  }

  inputValueChanged(event) {
    this.updateSum();
  }

  updateSum() {
    let sum = 0;
    this.totalTargets.map((item) => {
      sum = sum + convertToNumber(item.value)
    });
    this.total_sumTarget.textContent = convertToString(sum);
  }

  saveRecords(event) {
    const fares = $j("input[name='lancamento[fare]']");
    const work_days = $j("input[name='lancamento[work_days]']");
    const ids = $j("input[name='lancamento[id]']");
    const AUTH_TOKEN = $j('meta[name=csrf-token]').attr('content');
    const release = event.currentTarget.getAttribute("data-operation") == "release";
    const approve = event.currentTarget.getAttribute("data-operation") == "approve";
    let updates = 0;
    ids.each(function (index) {
      const id = $j(ids[index]).val();
      const fare = $j(fares[index]).val();
      const work_day = $j(work_days[index]).val()
      console.log(index, fare);
      $j.post("/lancamento_vts/update/" + id + "?&authenticity_token=" + AUTH_TOKEN,
        { lancamento: { id: id, fare: fare, work_days: work_day }, release: release, approve: approve }, function () {
          updates = updates + 1;
          if (updates == ids.length) {
            location.reload();
          }
        })
    })
  }
}
