import { Controller } from '@hotwired/stimulus';

export default class AppMenuController extends Controller {
  static targets = ['openButton', 'closeButton', 'mobileMenu'];

  connect() {
  }

  open() {
    this.openButtonTarget.classList.add('hidden')
    this.closeButtonTarget.classList.remove('hidden')
    this.mobileMenuTarget.classList.remove('hidden')
  }

  close() {
    this.openButtonTarget.classList.remove('hidden')
    this.closeButtonTarget.classList.add('hidden')
    this.mobileMenuTarget.classList.add('hidden')
  }
}
