import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="batch-process"
export default class extends Controller {
  static targets = ["services", "serviceId"];

  connect() {
    this.#setServiceId(this.servicesTarget.value);
  }

  changeService(event) {
    const serviceId = event.currentTarget.value;
    this.#setServiceId(serviceId);
  }

  #setServiceId(id) {
    this.serviceIdTargets.forEach((element, index) => {
      element.setAttribute("value", id);
    });
  }
}
