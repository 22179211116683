// Import and register all your controllers from the importmap under controllers/*
import { registerControllers } from 'stimulus-vite-helpers';
import Chart from "stimulus-chartjs";

import { application } from "./application.js";

// Controller files must be named *_controller.js.
const controllers = import.meta.globEager('./**/*_controller.js');
registerControllers(application, controllers);

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)

import {
  // Alert,
  // Autosave,
  Dropdown,
  Modal,
  // Tabs,
  // Popover,
  // Toggle,
  // Slideover,
} from "tailwindcss-stimulus-components";
application.register("dropdown", Dropdown);
application.register("modal", Modal);
application.register("chart", Chart);
// application.register("alert", Alert);
// application.register("autosave", Autosave);
// application.register("tabs", Tabs);
// application.register("popover", Popover);
// application.register("toggle", Toggle);
// application.register("slideover", Slideover);
