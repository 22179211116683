import { Controller } from "@hotwired/stimulus"

function convertToNumber(str) {
  const num = Number(str.replace(/[^0-9,-]+/g, "").replace(",", "."));
  return num;
}

function convertToString(num) {
  const str = num.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
  return str;
}

// Connects to data-controller="lancamento-vts"
export default class extends Controller {
  static targets = ["fare", "commute_times", "work_days", "total"]
  connect() {
  }
  calculoTotal() {
    const fare = convertToNumber(this.fareTarget.value);
    const commute_times = convertToNumber(this.commute_timesTarget.value);
    const work_days = convertToNumber(this.work_daysTarget.value);
    const total = fare * commute_times * work_days;
    this.totalTarget.value = convertToString(total)
    const event = new CustomEvent("update-sum");
    window.dispatchEvent(event);
  }
  inputSomenteNumeros(event) {
    const target = event.target;
    let str = target.value;
    target.value = str.replace(/[^\.\d,]/, "");
  }
  inputSomenteInteiros(event) {
    const target = event.target;
    const maxValue = target.dataset.maxValue;
    let str = target.value;
    target.value = str.replace(/[^\d]/, "");
    if (maxValue && target.value && parseInt(target.value) > parseInt(maxValue)) {
      target.value = maxValue;
    }
  }
}
