import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit", 'cpf', 'form'];

  connect() {
    console.log('connectado!')
  }

  submit() {
    event.preventDefault()

    console.log('foi!!!!!!')
    const movimentacoesPessoalTarget = this //.movimentacoesPessoalTarget;

    const cpfConsulta = movimentacoesPessoalTarget.cpfTarget.value;
    console.log('cpf: ', cpfConsulta)

    // if (!movimentacoesPessoalTarget.cpf_consultaTarget.value) {
    //   movimentacoesPessoalTarget.cpf_consultaTarget.set("invalid", true);
    //   movimentacoesPessoalTarget.cpf_consultaTarget.set("error", "O CPF da consulta é obrigatório");
    // }

    // Envia o formulário para o controller MovimentacoesPessoais
    // fetch("/movimentacoes_pessoais", {
    //   method: "post",
    //   body: JSON.stringify({
    //     movimentacoes_pessoal: movimentacoesPessoalTarget.serialize(),
    //     params: { nome_candidato: this.params_nome_candidatoTarget.value },
    //   }),
    // });
  }
}
